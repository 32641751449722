*{
    padding: 0;
    margin: 0;
    font-family: 애플 SD 산돌고딕 Neo (Apple SD Gothic Neo);
}
.mobile_header{
    display:none;
}

.top{
    background:url(../assets/images/top.png) no-repeat center/cover;
    width: 100%;
    height:785px;
    margin-top: 40px;
    :global{
        .top-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 1300px;
            margin: 0 auto;
            padding-top: 115px;
            .top-text{
                color: white;
                position: relative;
                h1{
                    font-size: 60px;
                }
                div:nth-of-type(1){
                    margin-top: 50px;
                }
                >div{
                    display: flex;
                    align-items: center;
                    font-size: 40px;
                   padding-right:50px ;
                    img{
                        width: 33px;
                        height: 36px;
                    }
                }
                >img{
                    width: 466px;
                    height: 585px;
                    position: absolute;                 
                    right: -182px;
                    top: -60px;
                }

            }
        }

    }
}

.h1_title{
    font-size: 35px;
    color: #0E2463;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 31px 0;
    &::after{
        content: "";
        display: inline-block;
        width: 100px;
        height: 5px;
        margin: 0 10px;
        background: #EDAB02;
    }
    &::before{
        content: "";
        width: 100px;
        height: 5px;
        margin: 0 10px;
        display: inline-block;
        background: #EDAB02;
    }
}

.reward{
    width: 1050px;
    margin: 0 auto;
    margin-top: 45px;
    >div{
        width: 100%;
        height: 561px;
        background: #E5EEFD;
        border-radius: 20px;
    }
    :global{
        .reward-box{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
             padding: 26px 0;
        }
        .reward-text{
            color:#2E499C ;
            font-size: 24px;
            text-align: right;
            >div{
                height: 67px;
                line-height: 67px;
                margin: 25px 0;
            }
        }
        .reward-img{
            >div{
                position:relative;
                z-index: 99;
                width: 67px;
                height: 67px;
                background: #EDAB02;
                border: 0px solid #923E31;
                border-radius: 50%;
                margin: 25px 20px;
                text-align: center;
                line-height: 6;
                img{
                    width: 41px;
                    height: 41px;
                }
            }
            div:nth-of-type(4){
                img{
                    width: 30px;
                    height: 38px;
                }
            }
            
        }
        .reward-bonus{
            >div{
                width: 248px;
                height: 56px;
                background: #2D489E;
                border: 0px solid #923E31;
                border-radius: 6px;
                color: white;
                line-height: 56px;
                font-size: 36px;
                margin: 35px 0;
                position: relative;
                text-indent: 15px;
                &::before {
                    content: '';
                    position: absolute;
                    border: 8px solid;
                    border-color: transparent #2D489E transparent transparent;
                    left: -16px;
                    top: 20px;
                  }
              
            }
            
        }
        .vertical-line {  
            position: absolute;
            top: 39px;
            // left: 50%;
            width: 3px;
            height: 469px;
            border: 3px solid #2E4A9D;
            &::before{
                position: absolute;
                top: -15px;
                left: -7px;            
                content:"";
                display: inline-block;
                width: 14px;
                height: 14px;
                background: #2C4695;
                border-radius: 50%;
            }
            &::after{
                position: absolute;
                bottom: -15px;
                left: -7px;
                content:"";
                display: inline-block;
                width: 14px;
                height: 14px;
                background: #2C4695;
                border-radius: 50%;
            }
            
          }
    }
}





.tabs{
    width:1050px;
    margin: 0 auto;
    margin-top: 39px ;
        >div{
            width: 100%;
            height: auto;
            padding: 47px 0;
            background: #E5EEFD;
            border-radius: 20px;
        }
    :global(.tabs_table){
        width: 905px;
        text-align:center;
        border-radius: 20px;
        // border-collapse: collapse;
        margin: 0 auto;
        th,td{
          border:none;
          font-size: 24px;
          padding: 12px 10px;
          color: #0E2463;
        }
        th{
          font-size:30px;
          padding: 14px 10px;
        }
        tr{
            td:nth-of-type(2){
                color: #0E2463;
            }
            td:nth-of-type(3){
              color: #FFA200;
          }
        }
        thead{
            tr{
              background: rgba(14,36,99,.3);
            }
        }
        tbody {
            tr:nth-of-type(odd) {
              background: #F0F4FA;
            }
            tr:nth-of-type(even) {
              background: #F7F9FC;
            }
        }
       
      }

}

.tab_register{
    width: 800px;
    margin: 0 auto;
    text-align: center;
    font-size: 24px;
    margin-top: 39px;
    
    :global(.slick-slide){
        width: auto !important;
        padding: 0 20px;
    }
    
    h3{
        font-size: 24px;
        margin: 0;
        margin-bottom: 45px;
        span{
            color: #FFA200;
        }
    }
    a{
        padding: 17px 230px;
        background: #FFA200;
        border-radius: 10px;
        font-size:18px;
        color: #0E2463;
        margin-top: 15px;
    }
}

.reason{
    width:1050px;
    margin: 0 auto;
    margin-top: 39px ;
    >div{
        width: 100%;
        height: auto;
        background: #E5EEFD;
        border-radius: 20px;
        padding: 50px 70px;
    }
    :global{
        .reason-box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            >div{
                width: 442px;
                height: 220px;
                background: #FFFFFF;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 20px;
                color: #585858;
                margin:16px 0 ;
                padding: 15px;
                h1{
                    font-size: 48px;
                    color: #FFA102;
                }
                div{
                    width: 215px;
                    text-align:center
                }
            }
            div:nth-of-type(1){
                img{
                    width:204px ;
                    height: 166px;
                }
            }
            div:nth-of-type(2){
                img{
                    width: 197px;
                    height: 191px;
                }
            }
            div:nth-of-type(3){
                img{
                    width: 200px;
                    height: 150px;
                }
            }
            div:nth-of-type(4){
                img{
                    width: 176px;
                    height: 149px;
                }
            }
            div:nth-of-type(5){
                img{
                    width: 199px;
                    height: 179px;
                }
            }
            div:nth-of-type(6){
                img{
                    width: 169px;
                    height: 150px;
                }
            }
        }
    }
}

.faq{
    width:1050px;
    margin: 0 auto;
    margin-top: 39px ;
    >div{
        width: 100%;
        height: auto;
        background: #E5EEFD;
        border-radius: 20px;
        padding: 21px 31px;
    }
    :global{
        .faq-box{
            margin: 10px 0;
            color: #2E479D;
            >div{
                display: flex;
                align-items:flex-start;
                font-size: 20px;
                margin-bottom: 30px;
                span{
                    font-size: 24px;
                    font-weight: 600;
                }
                >div{
                    margin-left: 45px;
                    line-height: 1.5;
                }
            }
        }
    }
}



footer{
    width: 100%;
    padding: 38px 0 80px;
    background: #1B2F79;
    margin-top: 20px;
    >div{
        width:1200px;
        margin: 0 auto;
        color: white;
        line-height: 2;
        font-size: 14px;
        text-align: center;

    }
}

.br{
    display: none;
}






@media screen and (max-width: 768px){
    .br{
        display: block;
    }
    .br1{
        display: none;
    }
    section{
        padding: 0 6vw;
    }
    .mobile_header{
        display:block;
        position: fixed;
        top: 0;
        width: 100%;
        // height: 16vw;
        background: #0F2648;
        z-index: 1000;
        display: flex;
        align-items: center;
        padding: 1vw 0.667vw;
        justify-content: space-around;
        p{
            margin: 0;
        }
        >img{
            width: 2.4vw;
            height: 2.4vw;
        }
        :global{
            .mobile_logo{
                width: 12vw;
                height: 12vw;
                border-radius: 1.333vw;
                background: #2E4A9D;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 1vw;
                img{
                    width: 8.667vw;
                    height: 8.667vw;
                }
            }
            .mobile_logo_jp{
                width: 14vw !important;
            }
            .mobile_text{
                color: #fff;
                margin-left: 4vw;
                width: 53.6vw;
                >div{
                    font-size:4.8vw;
                    font-weight:bold;
                    p{
                        font-size:3.2vw;
                        margin-top: -1vw;
                    }
                }
                >p{
                    margin-top: -1.767vw;
                    img{
                        width: 2.533vw;
                        height: 2.533vw;
                    }
                    
                }
            }
            .mobile_download{
                padding: 1.567vw 2.8vw;
                background: #FFFFFF;
                border-radius: 4.867vw;
                display: flex;
                align-items: center;
                // margin-left: 4.667vw;
                white-space: nowrap;
                img{
                    width: 4.133vw;
                    height: 3.333vw;
                    margin-left: 0.667vw;
                }
            }
        }
    }
    .top{
        background:url(../assets/images/bj-phone.png) no-repeat center/cover;
        width: 100%;
        height:auto;
        margin-top: 5.333vw;
        :global{
            .top-box{
                flex-direction: column;
                width: 100%;
                padding-top: 30.333vw;
                padding-bottom: 5vw;
                .top-text{
                    margin-bottom: 3vw;
                    h1{
                        font-size: 8vw;
                    }
                    div:nth-of-type(1){
                        margin-top: 6.667vw;
                    }
                    >div{
                        font-size: 5.333vw;
                        padding-right:6.667vw ;
                        img{
                            width: 4.4vw;
                            height: 4.8vw;
                            margin-right: 1vw;
                        }
                    }
                    >img{
                        width: 45.867vw;
                        height: 51.333vw;
                        right: -4.267vw;
                        top: -3vw;
                    }
                }
            }
        }
    }

    .h1_title{
        font-size: 4.667vw;
        margin: 4.133vw 0;
        text-align: center;
        &::after{
            width: 13.333vw;
            height: 0.667vw;
            margin: 0 1.933vw;
        }
        &::before{
            width: 13.333vw;
            height: 0.667vw;
            margin: 0 1.933vw;
        }
    }

    .reward{
        width: 100%;
        margin-top: 6vw;
        >div{
            width: 100%;
            height: auto;
            border-radius: 2.667vw;
        }
        :global{
            .reward-box{
                 padding: 3.467vw 0;
            }
            .reward-text{
                font-size: 3.2vw;
                >div{
                    height: 8.933vw;
                    line-height: 8.933vw;
                    margin: 3.333vw 0;
                }
            }
            .reward-img{
                >div{
                    width: 8.933vw;
                    height: 8.933vw;
                    border-radius: 50%;
                    margin: 3.333vw 2.667vw;
                    line-height:2.5;
                    img{
                        width: 5.467vw;
                        height: 5.467vw;
                    }
                }
                div:nth-of-type(4){
                    img{
                        width: 4vw;
                        height: 5.067vw;
                    }
                }
                
            }
            .reward-bonus{
                >div{
                    width: 33.067vw;
                    height: 7.467vw;
                    border-radius: 0.8vw;
                    line-height: 7.467vw;
                    font-size: 4.8vw;
                    margin: 4.667vw 0;
                    text-indent: 2vw;
                    &::before {
                        content: '';
                        border: 1.067vw solid;
                        border-color: transparent #2D489E transparent transparent;
                        left: -2.133vw;
                        top: 2.667vw;
                      }
                  
                }
                
            }
            .vertical-line {  
                top: 5.2vw;
                // left: 50%;
                width: 0.4vw;
                height: 62.533vw;
                border: 0.4vw solid #2E4A9D;
                &::before{
                    top: -2vw;
                    left: -0.933vw;            
                    width: 1.867vw;
                    height: 1.867vw;
                }
                &::after{
                    bottom: -2vw;
                    left: -0.933vw;
                    width: 1.867vw;
                    height: 1.867vw;
                }
                
              }
        }
    }


    .tabs{
        width:100%;
        margin: 0 auto;
        margin-top: 5.2vw ;
        padding:0 2vw;
        >div{
            width: 100%;
            height: auto;
            padding: 6.267vw 2.8vw;
            background: #E5EEFD;
            border-radius: 2.667vw;
        }
        :global(.tabs_table){
            width:100%;
            >thead{
                th:nth-of-type(1){
                    text-indent: 3vw;
                }
            }
            th,td{
              padding: 1.6vw 1.333vw;
            }
            td{
              font-size: 3.467vw;
            }
            th{
              font-size: 3.467vw;
              padding: 1.867vw 1.333vw;
              color: #0E2463;
            }
          }
    }

    .tab_register{
        width: 100%;
        font-size: 3.2vw;
        margin-top: 2.2vw;
        
        h3{
            font-size: 3.2vw;
            margin-bottom: 6vw;
        }
        a{
            width: 100%;
            padding: 4.267vw 10.667vw;
            border-radius: 1.333vw;
            font-size:2.8vw;
            margin-top: 0vw;
            display: inline-block;
        }
    }

    .reason{
        width:100%;
        margin-top: 5.2vw ;
        >div{
            border-radius: 2.667vw;
            padding: 5.6vw 1.867vw;
        }
        :global{
            .reason-box{
                >div{
                    width: 41.067vw;
                    height: 20.533vw;
                    border-radius: 2.667vw;
                    font-size: 1.867vw;
                    margin:2.133vw 0 ;
                    padding: 2vw 1vw;
                    h1{
                        font-size: 4.4vw;
                    }
                    div{
                        width: 28.667vw;
                    }
                }
                div:nth-of-type(1){
                    img{
                        width:18.933vw ;
                        height: 16.133vw;
                    }
                }
                div:nth-of-type(2){
                    img{
                        width: 17.867vw;
                        height: 17.733vw;
                    }
                }
                div:nth-of-type(3){
                    img{
                        width: 18.667vw;
                        height: 14vw;
                    }
                }
                div:nth-of-type(4){
                    img{
                        width: 16.533vw;
                        height: 14vw;
                    }
                }
                div:nth-of-type(5){
                    img{
                        width: 18.533vw;
                        height: 16.533vw;
                    }
                }
                div:nth-of-type(6){
                    img{
                        width: 15.867vw;
                        height: 14vw;
                    }
                }
            }
        }
    }

    .faq{
        width:100%;
        margin-top: 5.2vw ;
        >div{
            width: 100%;
            border-radius: 2.667vw;
            padding: 2.8vw 4.133vw;
        }
        :global{
            .faq-box{
                margin: 2vw 0;
                color: #2E479D;
                >div{
                    display: flex;
                    align-items:flex-start;
                    font-size: 2.667vw;
                    margin-bottom: 4vw;
                    span{
                        font-size: 3.2vw;
                    }
                    >div{
                        margin-left: 6vw;
                        line-height: 1.5;
                    }
                }
            }
        }
    }



    
    footer{
        width: 100%;
        padding: 5.067vw 4vw 3.733vw;
        margin-top: 3.667vw;
        font-size: 2.667vw;
        >div{
            width:100%;
            text-align: left;
        }
    }



}