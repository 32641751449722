.ant_header{
    width: 100%;
    height: 70px;
    background: #304c98;
    overflow: visible;
    position: fixed;
    top: 0;
    z-index: 999;
    padding: 0 15px;
    display: flex;
    align-items: center;
    color: white;
    :global(.logo){
            width: 126px;
            height: 31px;
            display: inline-block;
            cursor: pointer;
    }
    :global(.header-container){
        // display: inline-block;
        width: 280px;
    }
    :global(.header_box){
        width: 1200px;
        display:flex;
        align-items: center;
        margin: 0 auto;
    }
    :global(.header-div){
        display: flex;
        width: 100%;
        justify-content: right;
    }
}



.menu {
    background: #304c98;
    border-bottom: none; 
    // justify-content: right;
    display: flex;
    :global{
        li ::before{
            border-bottom: none !important;
        }
    }
    :global(.header-a){
        color: white !important;
        font-size: 20px;
    }
        .dropdown {
            z-index: 99;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 80px;
            cursor: pointer;
          
            >span {
              width: 100%;
              display: flex;
              justify-content: space-evenly;
          
              &:after {
                display: block;
                content: '';
                width: 8px;
                height: 8px;
                border: 2px solid #000;
                border-top: none;
                border-left: none;
                transform: rotate(45deg);
              }
            }
            :global {
                .dropdown-menu {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    max-height: 300px;
                    overflow-y: scroll;
                    background: #fff;
                    max-width: 130px;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    &.active {
                      display: block;
                      padding-top: 10px;
                      
                    }
                    &:hover {
                      color: #4d8cf5;
                    }
                
                    >div {
                      padding: 15px 20px;
                      display: flex;
                      justify-content: space-between;
                      color: #000;
                      transition: 0.25s;
                
                      &:hover {
                        background: rgba(100, 100, 100, 0.1);
                        transition: 0.25s;
                        
                      }
                    }
                  }
            }
           
          }
             
    
    .lang {
        position: relative;
        margin-left: 31px;
        padding-right: 2px;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
        span::after {
            content: none;
        }
      img{
        margin-left: 9px;
        width: 14px;
        height: 8px;
      }
       
       

        &:hover {
            :global {
                .dropdown-menu {
                    display: block;
                    width: 160px;
                    right: 0;
                    left: auto;
                    overflow: auto;
                    a {
                        display: block;
                        color: #000;
                        padding: 10px;
                        &:hover{
                            color: #4d8cf5;
                            background: rgba(77, 140, 245, 0.1);
                        }
                    }
                }
            }
        }
    }
}


@media(max-width:1080px) {
    .ant_header{
        position: absolute;
        width: 100vw;
        height: 10.667vw;
        top: 20vw;
        // margin-top: 18vw;
        :global(.header_box){
            justify-content: space-between;
        }
        :global(.header-div){
            max-width: 100px;
            align-items: center;
            // justify-content: right;
            flex: 1;
        }
        :global(.logo){
            width: 19.067vw;
            height: 4.8vw;
            display: inline-block;
            // justify-content:left;
            // flex: 1;
        }
        :global(.header-container){
            width: 50px;
        }
    }

 
    .menu {
        background: #304c98;
        border-bottom: none; 
        // justify-content: right;
        display: flex;
        margin-left: 20px;
        :global{
            li ::after{
                border-bottom: none !important;
            }
            li{
                line-height:36px;
                border-bottom: none !important;
                color: white !important;
                font-size: calc(26vw / 7.5);
            }
        }
        :global(.header-a){
            color: white !important;
            font-size: 20px;
        }
            .dropdown {
                min-width: 40px;
                height: auto;
                >span {
                  width: 100%;
                  display: flex;
                  justify-content: space-evenly;
              
                  &:after {
                    display: block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border: 2px solid #000;
                    border-top: none;
                    border-left: none;
                    transform: rotate(45deg);
                  }
                }
                :global {
                    .dropdown-menu {
                        max-width: 130px;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 10px;
                        &.active {
                          padding-top: 10px;
                          
                        }
                        &:hover {
                          color: #4d8cf5;
                        }
                    
                        >div {
                          padding: 15px 20px;
                    
                          &:hover {
                            background: rgba(100, 100, 100, 0.1);
                            transition: 0.25s;
                            
                          }
                        }
                      }
                }
               
              }
                 
        
        .lang {

            margin-left: 0;
            padding-right: 2px;
            font-size: 4.667vw;
            span::after {
                content: none;
            }
          img{
            margin-left: 9px;
            width: 5.067vw;
            height:5.067vw;
          }
           
           
    
            &:hover {
                :global {
                    .dropdown-menu {
                        display: block;
                        width: 160px;
                        right: 0;
                        left: auto;
                        overflow: auto;
                        a {
                            display: block;
                            color: #000;
                            padding: 10px;
                            &:hover{
                                color: #4d8cf5;
                                background: rgba(77, 140, 245, 0.1);
                            }
                        }
                    }
                }
            }
        }
    }
}
